import React, { useEffect } from "react"

import { Link } from "gatsby"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"

import { useRecoilState, useSetRecoilState } from "recoil"
import { PhoneIcon, CurrencyDollarIcon } from "@heroicons/react/solid"

import { convertToSlug } from "../helpers"

import {
  articlesEntriesAtom,
  articlesTotalPagesAtom,
  callbackModalOpen,
} from "../atoms"

import { getSearchResults, getArticles } from "../../services/articlesServices"
import useArticlesImages from "../../hooks/useArticlesImages"

const ListAllArticles = ({ query, brand, model, isLoading, setLoading }) => {
  const [entries, setEntries] = useRecoilState(articlesEntriesAtom)
  const setCallbackModalOpen = useSetRecoilState(callbackModalOpen)

  const setTotalPages = useSetRecoilState(articlesTotalPagesAtom)

  const fetchSearchEntries = async () => {
    setLoading(true)

    const fetchedEntries = await getSearchResults({
      query: query || "BWM",
    })

    setTotalPages(fetchedEntries[0]?.metadata[0]?.total / 10)
    setEntries(fetchedEntries[0]?.data)
    setLoading(false)
  }

  const fetchBrandEntries = async () => {
    setLoading(true)

    const fetchedEntries = await getArticles({
      brand: brand,
    })

    setTotalPages(0)
    setEntries(fetchedEntries[0]?.data)
    setLoading(false)
  }

  const fetchModelEntries = async () => {
    setLoading(true)

    const fetchedEntries = await getArticles({
      brand: brand,
      model: model,
    })

    setTotalPages(0)
    setEntries(fetchedEntries[0]?.data)
    setLoading(false)
  }

  useEffect(() => {
    if (model && brand) fetchModelEntries()
    if (!model && brand) fetchBrandEntries()
    if (!model && !brand) fetchSearchEntries()
  }, [query, brand, model])

  const images = useArticlesImages()
  const nodes = images.allFile.edges

  const Image = ({ entry }) => {
    const { brand, catalystNumber, img } = entry
    const imageName = img?.split("/").pop().split(".")[0]

    const filtered = nodes.find(node => node.node.name === imageName)
    const pathToImage = getImage(filtered?.node)

    if (!filtered)
      return (
        <div className="flex-shrink-0 justify-self-center rounded-sm">
          <StaticImage
            src="../assets/images/placeholder.svg"
            alt={`Катализатор от ${brand} ${catalystNumber}`}
            className="h-28 w-56"
          />
        </div>
      )

    return (
      <div className="flex-shrink-0 justify-self-center rounded-sm">
        <GatsbyImage
          image={pathToImage}
          alt={`Катализатор от ${brand} ${catalystNumber}`}
          className="h-28 w-56"
        />
      </div>
    )
  }

  if (entries === undefined) {
    return null
  }

  if (isLoading) return <div className="pt-1 text-gray-800">Загрузка...</div>

  if (entries !== undefined && entries !== null && entries.length === 0)
    return (
      <div className="pt-1 text-gray-800">
        Катализаторы по запросу <span className="font-semibold">{query}</span>{" "}
        не найдены
      </div>
    )

  return (
    <>
      {entries !== undefined && entries !== null && entries.length >= 0 ? (
        <>
          <div className="pt-1 text-gray-800">Результаты поиска</div>

          <ul className="grid grid-cols-1 gap-3 pt-2 sm:gap-6 lg:grid-cols-2">
            {entries?.map(entry => (
              <li
                key={entry.uuid}
                className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow"
              >
                <Link
                  to={`${convertToSlug(entry.catalystNumber)}${
                    query ? `?q=${query}` : ""
                  }`}
                  className="justify-items-left grid w-full grid-cols-1 items-center justify-between space-x-6 px-6 py-2 sm:flex"
                >
                  <div className="flex-1 truncate">
                    <h3 className="truncate text-left text-sm font-medium text-gray-900">
                      Марка: {entry?.brand}
                    </h3>

                    {/* <p className="mt-1 truncate text-sm text-gray-500">
                      Артикул: {entry?.catalystNumber}
                    </p> */}
                    <div className="flex flex-wrap space-x-1 pt-2">
                      <span className="text-sm font-medium text-gray-900">
                        Артикул:{" "}
                      </span>
                      {entry?.numbers &&
                        entry?.numbers.map(number => (
                          <div
                            key={number}
                            className="truncate rounded-full border  border-gray-300 bg-gray-50 px-2 text-sm font-light text-gray-500"
                          >
                            {number}
                          </div>
                        ))}
                    </div>

                    {entry?.description ? (
                      <p className="mt-1 truncate text-sm text-gray-500">
                        Описание: {entry?.description}
                      </p>
                    ) : null}
                  </div>

                  <Image entry={entry} />
                  {/* <img
                    className="h-32 w-auto max-w-xs flex-shrink-0 justify-self-center rounded-sm"
                    src={
                      entry?.img !==
                      "https://katalizatorychrzanow.pl/wp-content/uploads/2018/09/ds-auto_2018-e1536316489623.png"
                        ? entry?.img
                        : placeholderImg
                    }
                    alt={`Катализатор от ${entry.brand} ${entry.catalystNumber}`}
                  /> */}
                </Link>
                <div>
                  <div className="-mt-px flex divide-x divide-gray-200">
                    <div className="flex w-0 flex-1">
                      <Link
                        to={`${convertToSlug(entry.catalystNumber)}${
                          query ? `?q=${query}` : ""
                        }`}
                        className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center rounded-bl-lg border border-transparent py-4 text-sm font-medium text-gray-700 hover:text-gray-500"
                      >
                        <CurrencyDollarIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                        <span className="ml-3">Узнать цену</span>
                      </Link>
                    </div>
                    <div className="-ml-px flex w-0 flex-1">
                      <button
                        onClick={() => setCallbackModalOpen(true)}
                        className="relative inline-flex w-0 flex-1 cursor-pointer items-center justify-center rounded-br-lg border border-transparent py-4 text-sm font-medium text-gray-700 hover:text-gray-500"
                      >
                        <PhoneIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                        <span className="ml-3">Продать</span>
                      </button>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </>
      ) : (
        <div>Начните ввод или выберите марку для поиска</div>
      )}
    </>
  )
}

export default ListAllArticles
