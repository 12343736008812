import React, { useEffect } from "react"
import { useRecoilState } from "recoil"

import ReactPaginate from "react-paginate"

import {
  articlesEntriesAtom,
  articlesPageAtom,
  articlesTotalPagesAtom,
} from "../atoms"

import { getSearchResults } from "../../services/articlesServices"

function Pagination({ query }) {
  const [, setEntries] = useRecoilState(articlesEntriesAtom)
  const [page, setPage] = useRecoilState(articlesPageAtom)
  const [totalPages, setTotalPages] = useRecoilState(articlesTotalPagesAtom)

  useEffect(() => setPage(1), [query])

  const fetchEntries = async () => {
    const fetchedEntries = await getSearchResults({
      query: query || "BWM",
      page: page - 1,
    })
    setEntries(fetchedEntries[0]?.data)
    setTotalPages(fetchedEntries[0]?.metadata[0]?.total / 10)
  }

  const handlePageClick = event => {
    setPage(event.selected + 1)
    fetchEntries()
    window.scrollTo(0, 0)
  }

  return (
    <ReactPaginate
      breakLabel="..."
      nextLabel="→"
      onPageChange={handlePageClick}
      pageRangeDisplayed={3}
      pageCount={totalPages}
      previousLabel="←"
      renderOnZeroPageCount={null}
      className="relative z-0 mt-4 inline-flex -space-x-px rounded-md shadow-sm"
      pageClassName="relative inline-flex items-center border px-4 py-2 border-gray-300 bg-white hover:bg-gray-50"
      pageLinkClassName="text-sm font-medium text-gray-500"
      activeClassName="relative inline-flex items-center border px-4 py-2 z-10 border-orange-500 bg-orange-50"
      activeLinkClassName="text-sm font-medium text-orange-600"
      previousClassName="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 hover:bg-gray-50"
      nextClassName="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 hover:bg-gray-50"
      previousLinkClassName="text-sm font-medium text-gray-500"
      nextLinkClassName="text-sm font-medium text-gray-500"
      breakClassName="relative inline-flex items-center border border-gray-300 bg-white px-3 py-2"
      breakLinkClassName="text-sm font-medium text-gray-500"
    />
  )
}

export default Pagination
