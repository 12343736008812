import React, { useState } from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import ListAllArticles from "../../components/Articles/ListAllArticles"
import ArticlesWrapper from "../../components/Articles/ArticlesWrapper"
import Pagination from "../../components/Articles/Pagination"
import SearchForm from "../../components/Articles/SearchForm"
import VisitHistory from "../../components/Articles/VisitHistory"

import BrandSelect from "../../components/Articles/BrandSelect"
import ModelSelect from "../../components/Articles/ModelSelect"

const ArticlesPage = ({ location }) => {
  const params = new URLSearchParams(location.search)

  const query = params.get("q") || ""
  const brand = params.get("b") || ""
  const model = params.get("m") || ""
  // const page = params.get("page") || ""

  const [isLoading, setLoading] = useState(false)

  return (
    <Layout>
      {
        // eslint-disable-next-line
        <SEO
          title="Поиск катализаторов по артикулу и марке"
          description="Каталог артикулов катализаторов, поиск по номеру банки и модели с ценами и фото"
        />
      }
      <div className="overflow-hidden bg-gray-100 px-1 pt-20 lg:pt-16">
        <ArticlesWrapper>
          <div className="my-2">
            <SearchForm query={query} />
          </div>
          <div className="text-center text-sm font-medium text-gray-700 sm:text-left">
            ИЛИ
          </div>
          <div className="flex flex-col space-x-0 sm:flex-row sm:space-x-2">
            <BrandSelect brand={brand} />
            <ModelSelect brandQuery={brand} modelQuery={model} />
          </div>

          <ListAllArticles
            query={query}
            brand={brand}
            model={model}
            isLoading={isLoading}
            setLoading={setLoading}
          />
          <Pagination query={query} />
        </ArticlesWrapper>
        <VisitHistory />
      </div>
    </Layout>
  )
}

export default ArticlesPage
